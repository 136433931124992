// Override default variables before the import
// $body-bg: #282c34;
// $theme-colors: (
//     "success": #4585f9
// );

.pointer {
  cursor: pointer;
}

.text-transform-none {
  text-transform: none;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.capitalize-case {
  text-transform: capitalize;
}

.container {
  margin-top: 100px;
}
// Import Bootstrap and its default variables
// @import '~bootstrap/scss/bootstrap.scss';
@import '~bootswatch/dist/darkly/bootstrap.min.css';
